import React from 'react';

import wideLogo from '../assets/logoWide_white.png';
import narrowLogo from '../assets/logoShort_white.png';
import expandArrow from '../assets/expand.svg';

import '../css/Header.css';


class Header extends React.Component {
    render() {
        return (
            <div className='header__wrapper'>
                <div className='foreground__wrapper'>
                    <h1>Wijnen Kint</h1>
                    <img src={window.innerWidth > 950 ? wideLogo : narrowLogo} alt={"logo"} className='logo' />
                    <img src={expandArrow} alt={"expand"} className='expand' />
                </div>
            </div>
        )
    }
}

export default Header;
