import React from 'react';
import '../css/Contact.css';

class Contact extends React.Component {
    render() {
        return (
            <div className='contact__wrapper'>
                <h2>Contact - Openingsuren</h2>
                <div className='text__wrapper'>
                    <div className='left__wrapper text'>
                        <a href='https://goo.gl/maps/vbPNc5Srt58FnzaFA' target='_blank' rel="noreferrer">Harelbekestraat 8, 8570 Vichte</a>
                        <a href='tel:+32472094809' target='_blank' rel="noreferrer">tel. +32 472 09 48 09</a>
                        <a href='mailto:info@nathaliekint.be' target='_blank' rel="noreferrer">info@nathaliekint.be</a>
                    </div>
                    <div className='right__wrapper text'>
                        <p>Woensdag: 14u tot 18u</p>
                        <p>Vrijdag: 14u tot 18u</p>
                        <p>Zaterdag: 10u tot 18u</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;
