import imagePath from './webP/pyros.webp';

const spotlight = {
	"Title": "Fijne feestdagen!",
	"InnerHtml":
		<div>
			<p className='spotlight_text'>
				Nog een geschenkje nodig?<br/> We zijn open op dinsdag 24 en 31 december van 10u tot 12u en van 14u tot 16u. 
			</p>
		</div>,
	"ImagePath": imagePath
};

export default spotlight;
