import React from 'react';
import '../css/Footer.css';

class Footer extends React.Component {
    render() {
        return (
            <div className='footer__wrapper'>
                <p>Design & Development by Laurent Duquesnoy</p>
            </div>
        );
    }
}

export default Footer;
