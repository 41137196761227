import React from 'react';
import '../css/Vineyard.css';

class Vineyard extends React.Component {
    render() {
        return (
            <div className='vineyard__wrapper'>
                <div className='vineyard__text--wrapper'>
                    <h2>Eigen wijngaard</h2>
                    <div className='vineyard__text'>
                        <p>
                            In de Bourgogne in Frankrijk voel ik mij flamand-bourguignon, een beetje thuis,
                            ook al is het 600 km ver.
                        </p>
                        <p>
                            Santenay ligt in het departement Côte-d’Or en ligt op een
                            kwartiertje van de pittoreske stad Beaune. De wijngaard is 1.5 ha groot,
                            hoofdzakelijk beplant met Pinot Noir aangevuld met 17 ares Chardonnay.
                            We bevinden ons in de Côte de Beaune naast Chassagne-Montrachet en de lieudit is La Comme.
                        </p>
                        <p>
                            Ik ben bijzonder fier om jullie mijn Santenay wit en rood aan te kunnen bieden.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Vineyard;
