import React from 'react';
import '../css/About.css';
import Nathalie from '../assets/webP/nathalie.webp';

class About extends React.Component {
    render() {
        return (
            <div className='about__wrapper'>
                <div className='about__text--wrapper'>
                    <h2 className='about__title'>Over mij</h2>
                    <div className='about__text--content'>
                        <p>
                            Ik ben Nathalie Kint, ondertussen al meer dan 10 jaar wijnhandelaar te Vichte.
                        </p>
                        <p>
                            Zoals elke gezonde wijnrank heb ik ook diepe wortels…
                            Alles begon bij mijn grootvader Albert Kint die samen met mijn bomma Yvonne Carton in 1943 een drankenwinkel opende in Waregem.
                            Later vervoegden de drie kinderen de zaak.  Zij breidden de winkel uit tot een heuse groothandel.
                        </p>
                        <p>
                            Mijn vader zaliger Yvan Kint lanceerde in 1979 Wortegemsen, hét geesteskind van de familie.
                        </p>
                        <p>
                            Bijna 20 jaar werkte ik als derde generatie in het familiebedrijf.
                        </p>
                        <p>
                            In oktober 2011 ben ik gestart met mijn eigen winkel.
                            De passie voor wijn & gedistilleerd en de droom om een eigen zaak te starten bleven mij achtervolgen.
                        </p>
                        <p>
                            Kwaliteitswijnen aanbieden in vertrouwen en persoonlijke relaties opbouwen met mijn klanten geven mij enorm veel voldoening.
                            Naast het winkelverhaal verwen ik mijn klanten ook graag met gezellige wijnevents.
                        </p>
                        <p>
                            Mijn smaak is eerder klassiek, zeer Frans getint.  Bourgogne en Bordeaux zijn mijn favoriete wijnregio’s.
                            Bourgogne gezien mijn eigen wijngaard en Bordeaux gezien mijn directe contacten met diverse grote wijnkastelen.
                            Daarnaast ben ik ook verliefd geworden op Zuid-Afrikaanse wijnen, veelal klassiek gevinifieerd met Franse druiven.
                        </p>
                        <p>
                            Een ware passie … het beheerst mijn leven maar vooral … ik word er blij van.
                        </p>
                    </div>
                </div>
                <img src={Nathalie} alt='Nathalie' />
            </div>
        )
    }
}

export default About;
