import React from 'react';
import '../css/Newsletter.css';
import loading from '../assets/loading.svg';

class Newsletter extends React.Component {
    constructor() {
        super();
        this.state =
            {
                input: '',
                buttonState: 'Schrijf in',
                buttonClass: ''
            }
    }

    inputChange = e => {
        this.setState({
            input: e.target.value,
            buttonState: "Schrijf in"
        })
    }

    handleClick = async () => {
        this.setState(
            {
                buttonState: <img src={loading} className='form__loading' alt='loading'/>,
                buttonClass: 'button__loading'
            })

        if (this.state.input === '') {
            this.setState({buttonState: 'Ongeldig email-adres'});
            return;
        }

        const key = "7862VgSRUxHCoZDiE1NiEPZjOeT5Z8qayMcsWdtfdXB0AzFumwr8eg==";
        const target = 'https://wnkapi.azurewebsites.net/api/SubscribeToNewsletter?code=';

        fetch(`${target}${key}`, {
            method: 'POST',
            body: this.state.input

        }).then(response => {
            if (!response.ok) {
                this.setState({buttonState: 'Technisch probleem. probeer het later opnieuw'})
            }
            response.json().then(result => {
                if(result.success){
                    this.setState({
                        buttonState: 'Succesvol geabonneerd!',
                        buttonClass: 'positive'
                    })
                }else if (!result.success){
                    this.setState({
                        buttonState: result.errors,
                        buttonClass: 'negative'
                    })
                }
            })
        })
    }


    render() {
        return (
            <div className='newsletter__wrapper'>
                <h2>Nieuwsbrief</h2>
                <p>
                    Schrijf in op mijn nieuwsbrief en ontvang de laatste updates
                    over events, promoties en andere topics.
                </p>
                <div className='form__wrapper'>
                    <input
                        placeholder='voornaam.naam@voorbeeld.com'
                        type='email'
                        value={this.state.input}
                        onChange={this.inputChange}/>
                    <button className={'button__confirm ' + this.state.buttonClass}
                            onClick={this.handleClick}
                    >{this.state.buttonState}
                    </button>
                </div>
                <span className='newsletter__latest'>
                    Mijn laatste nieuwsbrief kunt u op <a href='https://mailchi.mp/e99a53cd7ba1/love-is-in-the-air'
                                                          target='blank' rel='noreferrer'>deze link</a> vinden.
                </span>
            </div>
        )
    }
}

export default Newsletter;
